import React from "react"
import { slideContainer, slider } from "./remote.module.scss"
import io from "socket.io-client"

class Remote extends React.Component {
  constructor(props) {
    super(props)

    this.dragging = false
    this.state = {
      value: 0,
    }
    this.socket = null
    this.roomId = null
  }

  changeValue(e) {
    let newValue = e.target.value
    if (this.state.value !== newValue && this.socket != null) {
      this.socket.emit("valueChange", {
        roomId: this.roomId,
        value: this.state.value,
      });
    }

    this.setState({
      value: e.target.value,
    })
  }

  parseQueryString = function(queryString) {
    var params = {},
      queries,
      temp,
      i,
      l

    // Split into key/value pairs
    queries = queryString.split("&")

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split("=")
      params[temp[0]] = temp[1]
    }

    return params
  }

  componentDidMount() {
    let queryString = window.location.search.substring(1)
    let queryData = this.parseQueryString(queryString)
    console.log(queryData)
    this.socket = io({ path: "/api/v1/socket.io" });

    this.socket.on("connect", () => {
      console.log("socket connected")

      this.socket.on("valueChange", data => {

        if (!this.dragging) {
          console.log("setting slider value")
          this.setState({
            value: data,
          })
        }
      })

      this.socket.on("joinedRoom", roomId => {
        console.log("happily joined " + roomId)
        this.roomId = roomId;
      })
      this.socket.emit("joinRoom", queryData.roomId)
    })
  }

  render() {
    return (
      <div className={slideContainer}>
        Slide me!
        <input
          className={slider}
          onTouchStart={() => (this.dragging = true)}
          onMouseDown={() => (this.dragging = true)}
          onTouchEnd={() => (this.dragging = false)}
          onMouseUp={() => (this.dragging = false)}
          type="range"
          min="0"
          max="100"
          onChange={e => this.changeValue(e)}
          value={this.state.value}
        />
      </div>
    )
  }
}

export default Remote
